<template>
    <div class="deallog">
        <Top name="成交动态" back email></Top>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="height:calc(100% - 40px);">
            <div class="foot_w">
                <van-list
                    class="list_side"
                    v-model="loading"
                    offset="50"
                    :finished="finished"
                    finished-text="没有更多了"
                    :error.sync="error"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                >
                    <van-cell v-for="item in list" :key="item.id" >
                        <router-link to>
                            <div class="index_list">
                                <span class="index_list_l">
                                    <img class="profilePhoto" :src="item.imgSrc">
                                    <span class="index_list_c">
                                        <!-- <div class="index_list_c_t">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div> -->
                                        <div class="index_list_c_t" v-if="item.onsale_type==1">{{item.channel_name}}\{{item.job}}\{{item.lv}}级</div>
                                        <div class="index_list_c_t" v-if="item.onsale_type==2 || item.onsale_type==4">{{item.channel_name}}\{{item.item_name}}×{{item.item_num}}万</div>
                                        <div class="index_list_c_t" v-if="item.onsale_type==3">{{item.channel_name}}\{{item.item_name}}</div>
                                        <div class="index_list_c_b">
                                            <span class="index_list_c_b_b">成交时间：{{item.surplus_time}}</span>
                                        </div>
                                    </span>
                                </span>
                                <span class="index_list_r">
                                    <div class="index_list_r_t">{{item.server_name}}</div>
                                    <div class="index_list_r_b" v-if="item.onsale_type !=2">￥{{item.price}}</div>
                                </span>
                            </div>
                        </router-link>
                    </van-cell>
                </van-list>
            </div>
        </van-pull-refresh>
    </div>
</template>
<script>
 import Top from '@/components/top.vue'

export default {
    name: 'Deallog',
    components: {
        Top,
    },
    data() {
        return {
            isLoading:false,
            loading: false,
            finished: false,
            error: false,
            list: [],
            total: 0,
            page: 1,
        }
    },
    methods: {
        onRefresh(){
            this.clearHttpRequestingList();
            this.error = false;
            this.loading = true;
            this.list = [];
            this.page = 1;
            this.finished = false;
            this.onLoad();
        },
        //列表加载
        onLoad() {
            if (this.isLoading) {
                this.finished = false;
                this.error = false;
                this.list = [];
                this.page = 1;
                this.isLoading = false;
            }
            // 异步更新数据
            this.$api.account.accountTransaction({
                status: 9,
                page: this.page
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.data.length > 0) {
                        res.data.data.forEach((item) => {
                            item.surplus_time = item.surplus_time.substring(0, 10);
                            this.list.push(item);
                        });
                        this.total = res.data.total;
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        if (this.page * 8  >= this.total) {
                            this.finished = true;
                        }
                        this.page ++;
                        //关掉错误提示
                        this.error = false;
                        this.list.forEach((item) => {
                            if(item.onsale_type!=1){
                                var imgSrc = '';
                                try{
                                    imgSrc = require('../../assets/images/itemicon/' + item.channel_id + '/' + item.icon_id + '.png');
                                }catch(e){
                                    imgSrc = require('../../assets/images/question.png');
                                }
                                
                                this.$set(item, 'imgSrc', imgSrc);
                                
                                return false 
                            }
                            //获取头像
                            if (item.sex == 200) {
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nan@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nan@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nan@2x.png');
                                }
                            }else{
                                switch(item.job) {
                                    case '战士':
                                        item.imgSrc = require('../../assets/images/photos/zs-nv@2x.png');
                                        break;
                                    case '法师':
                                        item.imgSrc = require('../../assets/images/photos/fa-nv@2x.png');
                                        break;
                                    default:
                                        item.imgSrc = require('../../assets/images/photos/ds-nv@2x.png');
                                }
                            }
                        });
                    }else{
                        //加载状态结束
                        this.loading = false;
                        //数据全部加载完成
                        this.finished = true;
                    }
                }else{
                    //加载状态结束
                    this.loading = false;
                    //错误提示
                    this.error = true;
                }
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        },
    }
}
</script>
