<template>
    
    <div class="top" style="height: 40px;">
        <van-sticky>
            <div class="public_header">
                <div class="back" v-if="back">
                    <van-icon  @click="backClick" name="arrow-left" color="#fff" :hasChild="isHas" />
                </div>
                <span>{{name}}</span>
                <router-link class="setting_link" to="/setting" v-if="setting">
                    <van-icon  name="setting-o" color="#fff" />
                </router-link>
                <router-link to="/message" v-if="email">
                    <van-icon  name="envelop-o" :dot='isdot' color="#fff" />
                </router-link>
            </div>
        </van-sticky>
    </div>
</template>
<script>
export default {
    name:'top',
    props: {
      back: Boolean,
      name: String,
      email:Boolean,
      setting: Boolean,
      isHas: String
    },
    data() {
        return {
            isdot: false,
            count: 0,
        }
    },
    created(){
        //有新消息小红点显示
        this.$bus.$on('isdot', data => {
            this.isdot = data;
        });
        let token = localStorage.getItem('token');
        if(token){
            this.$api.message.getList({page:1}).then((res)=>{
                if (res.code == 200) {
                    // console.log(res.data)
                    if(res.data){
                        res.data.forEach(element => {
                            if(element.is_read==0){
                                this.isdot = true;
                            } 
                        });
                    }
                    
                }
            })
        }
    },
    methods: {
        //判断是否有子路由跳转，没有子路由就返回上一级
        backClick() {
            let isRouter = localStorage.getItem('isRouter');
            if (this.isHas) {
                this.$router.push({
                    name: this.isHas
                });
            }else{
                if (isRouter == 'true' || window.history.length <= 2) {
                    this.$router.push({
                        name: 'Home'
                    });
                }else{
                    this.$router.back(-1);
                }
            }
        },
    },
}
</script>
<style scoped>
.van-sticky{position: fixed;top: 0;right: 0; left: 0;z-index: 2;}

</style>